import axios from "axios";

export const getQueryString = (data = {}) => {
    return Object.entries(data)
      .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
      .join('&');
}

export const client = axios.create({
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
    timeout: 5000,
    withCredentials: true,
    transformRequest: getQueryString
})

export const JSONClient = axios.create({
    headers: {'Content-Type': 'application/json'},
    timeout: 5000,
    withCredentials: true
})