import { useState, useEffect } from "react";
import LeftArrowSVG from "../svgs/LeftArrowSVG";
import RightArrowSVG from "../svgs/RightArrowSVG";
import DotSVG from "../svgs/DotSVG";

export type CarouselProps = {
    className?: string
    targetElementSelector?: string
    images: Array<string>
    children?: React.ReactNode
}

const Carousel: React.FC<CarouselProps> = ({ className = "ts-carousel", children, images, targetElementSelector }) => {
    const [activeItem, setActiveItem] = useState(images[0]);
    const [touchStart, setTouchStart] = useState(null);
    const [touchEnd, setTouchEnd] = useState(null);
    // the required distance between touchStart and touchEnd to be detected as a swipe
    const minSwipeDistance = 50

    const onTouchStart = (e: any) => {
        setTouchEnd(null) // otherwise the swipe is fired even with usual touch events
        setTouchStart(e.targetTouches[0].clientX)
    }

    const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX)

    const onTouchEnd = () => {
        if (!touchStart || !touchEnd) return
        const distance = touchStart - touchEnd
        const isLeftSwipe = distance > minSwipeDistance
        const isRightSwipe = distance < -minSwipeDistance

        if(isLeftSwipe) return onChildChange("forward", activeItem, null);
        else if(isRightSwipe) return onChildChange("back", activeItem, null);
    }

    const onChildChange = (progress: string, currentSelection: string, position: number | null) => {
        if (position !== null) {
            return setActiveItem(images[position]);
        }

        if (progress === "forward") {
            const current = images.indexOf(currentSelection);

            if (current + 1 !== images.length) {
                setActiveItem(images[current + 1]);
            } else if (current + 1 === images.length) setActiveItem(images[0]);
        } else {
            const current = images.indexOf(currentSelection);

            if (current === 0) {
                setActiveItem(images[images.length - 1]);
            } else setActiveItem(images[current - 1]);
        }
    }

    return (
        <div className={`${className} flex-center`}>
            {images.map((image, i) => {
                return (
                    <div 
                    key={i} 
                    className={`${className}_slide ${image === activeItem ? "active" : ""}`} 
                    style={{ backgroundImage: `url(${image})`, zIndex: image === activeItem ? 2 : 1 }}
                    onTouchStart={onTouchStart} 
                    onTouchMove={onTouchMove} 
                    onTouchEnd={onTouchEnd}
                    ></div>
                )
            })}
            <div className={`${className}_content`}>
                {children}
            </div>
            <div className={`${className}_position`}>
                {images.map((b, i) => <DotSVG className={activeItem === b ? "active" : ""} onClick={() => onChildChange("", "", i)} key={i} />)}
            </div>
            <div className={`${className}_back`}><LeftArrowSVG  onClick={() => onChildChange("back", activeItem, null)} /></div>
            <div className={`${className}_forward`}><RightArrowSVG  onClick={() => onChildChange("forward", activeItem, null)} /></div>
        </div>
    )
}

export default Carousel;