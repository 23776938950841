import { NavLink, useNavigate } from "react-router-dom";
import { companyName, companyPhoneNumber, servicesIncludeItems, websiteName, whyChooseUsItems } from "../util/constants";
import Carousel from "./Carousel/Carousel";
import hero1 from "../assets/imgs/hero-image-1.webp";
import hero2 from "../assets/imgs/hero-image-2.webp";
import hero3 from "../assets/imgs/hero-image-3.webp";
import squareImage1 from "../assets/imgs/square_fabrication_2.jpg";
import ArrowSVG from "./svgs/ArrowSVG";
import ArrowDownSVG from "./svgs/ArrowDownSVG";
import CheckSVG from "./svgs/CheckSVG";

const HomePage = () => {

    const navigate = useNavigate();
    const currentHour = new Date().getHours()
    const shopIsOpen = currentHour > 8 && currentHour < 18;

    return (
        <main>
            <section className="hero">
                <Carousel targetElementSelector=".hero" images={[hero1, hero2, hero3]}>
                    <div className="hero-content">
                        <h1>Quality Stone Countertops and More</h1>
                        <p style={{ fontWeight: "500" }}>
                            Ask About Price Matching | 1-Year Workmanship Warranty | Over 20 Years of Experience
                        </p>
                        <p><a href={`tel:${companyPhoneNumber}`}>{companyPhoneNumber}</a></p>
                        <button onClick={() => navigate("/contact")} className="cta-button">Get A Free Estimate</button>
                        { shopIsOpen ? 
                        <span><strong className="open">Open</strong> - Closes at 6pm</span> 
                        : <span><strong className="close">Closed</strong> - Opens at 8am</span> }
                        
                    </div>
                </Carousel>
            </section>
            <section className="twin">
                <div>
                    <img src={squareImage1} alt="excellent fabrication" />
                </div>
                <div className="flex-center column">
                    <div>
                        <h3 className="second-color">The Leading Maryland Remodeling Company</h3>
                        <p>
                            Welcome to {companyName} where your dream home is our dream too! Make every space in your home a true temple for your comfort.
                        </p>
                        <button onClick={() => navigate("/gallery")} className="cta-button">
                            More About Us
                        </button>
                    </div>
                </div>
            </section>
            <section>
                <h2 className="text-center second-color">Why Choose Us</h2>
                <div className="choose-us">
                    {whyChooseUsItems.map((item, i) => (
                        <div key={i}>
                            <h4>{item.title}</h4>
                            <p>{item.text}</p>
                        </div>
                    ))}
                </div>
                <h3 className="second-color">Specailists In</h3>
                {[{ title: "Granite", text: "Beauty and durability for your kitchen or bathroom." },
                { title: "Marble", text: "Elegance that transforms your spaces." },
                { title: "Quartz", text: "Modernity with outstanding durability." }].map((item, i) => (
                    <div key={i} className="specailist">
                        <h4><CheckSVG />  {item.title}</h4>
                        <p>{item.text}</p>
                    </div>
                ))}
            </section>
            <section>
                <h2 className="text-center second-color">Our Services Include</h2>
                <div className="services">
                    {servicesIncludeItems.map((item, i) => (
                        <div key={i} className="service flex-center">
                            <h3>{item.title}</h3>
                            <img src={item.image} alt={item.title} />
                        </div>
                    ))}
                </div>
            </section>
            <section className="process text-center">
                <h2>Our process</h2>
                <span>5 easy steps!</span>
                <ul className="flex-center column">
                    <li>set an appointment</li>
                    <ArrowDownSVG />
                    <li>pick out a color</li>
                    <ArrowDownSVG />
                    <li>make a contract</li>
                    <ArrowDownSVG />
                    <li>templating/fabricating</li>
                    <ArrowDownSVG />
                    <li>install</li>
                </ul>
            </section>
            <section className="outro flex-center column">
                <button onClick={() => navigate("/contact")} className="cta-button" style={{ margin: "auto" }}>Contact Us</button>
                <h2 className="text-center m-top-lg second-color">Take the First Step in Your Journey with {companyName}</h2>
                <p className="m-top-lg">Ready to get started?</p>
                <button onClick={() => navigate("/contact")} className="main-button m-top-lg">Let's Talk <ArrowSVG /></button>
            </section>
        </main>
    )
}

export default HomePage;