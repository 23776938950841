import { useRef, useEffect, useState } from "react";
import Isotope from "isotope-layout";
import { bathroomImgs, interiorImgs, kitchenImgs, servicesIncludeItems } from "../util/constants";
import { importAll } from "../util/utilFunctions";

const GalleryPage = () => {
  const isotope = useRef<Isotope | null>();
  // store the filter keyword in a state
  const [filterKey, setFilterKey] = useState("All");

  // initialize an Isotope object with configs
  useEffect(() => {
    setTimeout(() => {
      isotope.current = new Isotope(".filter-container", {
        itemSelector: ".filter-item",
        layoutMode: 'fitRows',
        // percentPosition: true
      });
    }, 1000)
    // cleanup
    return () => isotope.current?.destroy();
  }, []);

  // handling filter key change
  useEffect(() => {
    if (filterKey === "All") isotope.current?.arrange({ filter: `*` });
    else isotope.current?.arrange({ filter: `.${filterKey}` });
  }, [filterKey]);

  const kitchens = importAll(kitchenImgs);
  const bathrooms = importAll(bathroomImgs);
  const interiors = importAll(interiorImgs);

  const types = servicesIncludeItems.map(o => o.title);
  types.unshift("All");

  const handleFilterKeyChange = (key: string) => () => {
    setFilterKey(key);
  }

  return (
    <main>
      <h1 className="text-center second-color">Our Projects</h1>
      <h2 className="text-center">Some of our recent work</h2>
      <section className="gallery">
        <ul className="flex-evenly filter">
          {types.map((type, i) => (
            <li
              key={i}
              onClick={handleFilterKeyChange(type)}
              style={{ width: `calc(100% / ${types.length})` }}
              className={`flex-center ${type === filterKey ? "active" : ""}`}>
              {type}
            </li>
          ))}
        </ul>
        <hr />
          <ul className="filter-container">
            {kitchens.map((img: string, i: number) => (
              <div key={i} className="filter-item Kitchens"><img src={img} alt={`kitchen ${i}`} /></div>
            ))}
            {bathrooms.map((img: string, i: number) => (
              <div key={i} className="filter-item Bathrooms"><img src={img} alt={`kitchen ${i}`} /></div>
            ))}
            {interiors.map((img: string, i: number) => (
              <div key={i} className="filter-item Interiors"><img src={img} alt={`kitchen ${i}`} /></div>
            ))}
          </ul>
      </section>
    </main>
  )
}

export default GalleryPage;