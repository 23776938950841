import { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { companyName, companyPhoneNumber, navPaths } from "../util/constants";
import logo from "../assets/imgs/logo_small.png";
import starRating from "../assets/imgs/star_rating.png";
import LavaLampNav from "./LavaLampNav";

const Navbar = () => {

    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo({ top: 0 })
        const pathName = document.location.pathname.split("/")[1];

        if (pathName) {
            document.title = "MGC | " + pathName;
        } else {
            document.title = companyName;
        }
    })

    const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    const toggleMobileNav = (currentState: boolean) => setMobileNavOpen(!currentState);

    const handleNavChange = () => {
    }

    const mobileMenu = (
        <>
            <div className="top-section flex-between">
                <NavLink onClick={() => mobileNavOpen ? toggleMobileNav(mobileNavOpen) : null} to="/">
                    <div className="logo-container">
                        <img className="logo" src={logo} alt="logo" />
                    </div>
                </NavLink>
                {/* <button onClick={() => navigate("/contact")} className="cta-button">
                    CONTACT US
                </button> */}
                <div
                    className={mobileNavOpen ? "mobile-menu-button open" : "mobile-menu-button"}
                    role="button"
                    onClick={() => toggleMobileNav(mobileNavOpen)}>
                    <div className="menu-btn_burger"></div>
                </div>
            </div>
            <ul className={mobileNavOpen ? "mobile-menu mobile-toggle" : "mobile-menu"}>
                {navPaths.map((navPath, i) => (
                    <li key={i} className="link-item" onClick={() => toggleMobileNav(mobileNavOpen)}>
                        <NavLink
                            className=""
                            onClick={() => toggleMobileNav(mobileNavOpen)}
                            to={navPath.path}>
                            {navPath.name}
                        </NavLink>
                    </li>
                ))}
            </ul>
        </>
    )

    const desktopMenu = (
        <>
            <div className="top-section flex-between">
                <div>
                    <div className="stars">
                        {[1,2,3,4,5].map((star) => <div key={star}><img src={starRating} alt={`star${star}`} /></div>)}
                    </div>
                    <p style={{ margin: ".1rem 0" }}>{"( 12 Ratings & Reviews )"}</p>
                </div>
                <div className="logo-container">
                    <img className="logo" src={logo} alt="logo" />
                </div>
                <div className="cta">
                    <div>
                        <a href={`tel:${companyPhoneNumber}`}>{companyPhoneNumber}</a>
                    </div>
                    <button className="cta-button" onClick={() => navigate("/contact")}>Request a Consultation</button>
                </div>
            </div>
            <LavaLampNav navPaths={navPaths} />
        </>
    )

    return (
        <nav className="main-nav">
            {isMobile ? mobileMenu : desktopMenu}
        </nav>
    )
}

export default Navbar