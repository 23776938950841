const CheckSVG = () => {

    return (
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 700 700" xmlSpace="preserve">

        <path fill="#128413" fillRule="evenodd" clipRule="evenodd" d="M292.7,401.6L529.3,159c10.9-11.1,28.8-11.4,39.9-0.5l40.5,39.5c11.1,10.9,11.4,28.8,0.5,39.9L314.6,541  c-10.8,11.1-28.7,11.4-39.8,0.6l-170.4-165c-11.2-10.8-11.5-28.7-0.6-39.9l39.3-40.6c10.8-11.2,28.7-11.5,39.9-0.7L292.7,401.6  L292.7,401.6z"/>
        </svg>
    )
}

export default CheckSVG;